import * as React from "react";
import { FC } from "react";
import { FancyLine } from "../common/components/lines/fancy-line";
import { Migration } from "../common/components/migration/migration";
import {
  Heading,
  HEADING_CLASS
} from "../common/components/typography/heading";
import { useLocalized } from "../common/localization/localize";
import { ILocalizedPage } from "../common/types/page";
import { AwardMonteCarlo } from "../components/award-monte-carlo/award-monte-carlo";

export const AwardMonteCarloEndpoint: FC<ILocalizedPage> = _props => {
  const title = useLocalized("awardMonteCarlo_title");
  return (
    <>
      <Heading level={1} theme={HEADING_CLASS.MAIN}>
        {title}
      </Heading>
      <FancyLine />
      <p>{useLocalized("awardMonteCarlo_text_1")}</p>
      <AwardMonteCarlo />
      <p>{useLocalized("awardMonteCarlo_text_2")}</p>
    </>
  );
};
